import React from "react";
import Breadcrumb from "./Breadcrumb";
import semiIntegrated from "../images/physicalGiftCards/semiIntegrated.png";
import card_balance from "../images/physicalGiftCards/card_balance.png";
import card_number from "../images/physicalGiftCards/card_number.png";
import card_sale_icon1 from "../images/physicalGiftCards/card_sale_icon1.PNG";
import card_sale_icon2 from "../images/physicalGiftCards/card_sale_icon2.png";
import card_sale from "../images/physicalGiftCards/card_sale.png";
import clear_queue from "../images/physicalGiftCards/clear_queue.png";
import pending_2 from "../images/physicalGiftCards/pending_2.png";
import pending from "../images/physicalGiftCards/pending.png";
import redemption from "../images/physicalGiftCards/redemption.png";
import sale_complete from "../images/physicalGiftCards/sale_complete.png";
import sell_cards from "../images/physicalGiftCards/sell_cards.png";

const ThirdPartyGiftCards = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="giftCards" className="space-y-4 mb-8">
          <h3 className="mb-2">Physical Gift cards</h3>
          <p>
            The following gift cards have been certified for both selling and -
            redemption in the Paymentree solution:
          </p>
          <ul className="feature-list">
            <li> Paymentree </li>
            <li> Givex </li>
            <li> Ackroo</li>
            <li> Data Candy</li>
            <li> Fiserve</li>
            <li> Focus 4</li>
          </ul>

          <p>
            The Paymentree gift cards can be printed by your choice of supplier.
            A formula for generating the card number will be available for these
            vendors.
          </p>

          <p>
            If the POS system does not have a gift card sku, simply create a
            non-merchandise, non-taxable sku or a deposit sku and sell it in the
            POS system. The amount should be the total for all the gift cards
            being sold in this transaction.
          </p>

          <p>
            Physical gift cards can be sold through a fully integrated or a
            semi-integrated solution.
          </p>

          <p>
            In the fully integrated solution, where the POS software provider
            has programmed to Paymentree, several options are available.
          </p>

          <p>
            If Android based payment terminals are used, the POS system creates
            the gift card sales transaction for the total amount of the gift
            cards being sold and will send a gift card sales transaction
            directly to the payment terminal for funding and payment.
          </p>
          <p>
            When the payment terminal receives this transaction, it will
            validate each card number and when the input total equals the
            transaction total it will be sent for payment. The terminal will use
            its camera to scan the bar code or QR code and process the card.
            Payment will be taken and the transaction will be completed.
          </p>
          <p>
            Redemption of these cards can be done on the payment terminal by
            using the camera on the terminal to scan either a bar code or QR
            code, either on the card or on a mobile phone. A tender code of GIFT
            is produced for the transaction.
          </p>
          <p>
            If legacy terminals such as Move5000, ICT250, etc., are used they
            can be accommodated in a Windows environment with Paymentree
            installed. Paymentree would process the gift card and the terminal
            would process the payment.
          </p>
          <p>
            In the semi-integrated mode, if no integration has been done, a gift
            card can still be sold on the payment terminal without requiring any
            programming.
          </p>
          <p>
            This function should be used for legacy payment terminals such as
            ICT250, Move5000, etc. It also should be used if gift card swipe is
            required for Android terminals. To use this functionality,
            Paymentree for windows must be installed.
          </p>
          <p>
            An executable is provided for windows-based systems to capture the
            gift card details. This can be used in both Android based and legacy
            systems After you totalize the sale, you will be presented with a
            selectable button for entering the gift card details.
          </p>
          <p>
            The semi-integrated program will be running and appear as a small
            box which can be placed at any part of the monitor screen just by
            holding a left click on the button and dragging it.
          </p>
          <img
            src={semiIntegrated}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />

          <p>
            Click on the button to see the list of options/functionalities
            available.
          </p>
          <img
            src={sell_cards}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <h3 className="margin-top-30">Sell Cards</h3>
          <p>
            This option grants the ability to sell the gift card by funding it
            and this transaction will appear in the pending transactions list.
          </p>
          <p>
            The POS system should have a sku for recording the sale of the gift
            cards. If multiple cards are to be sold, the amount entered into the
            SKU should be the total of all the cards. After the sale is
            totalized, the gift card button should be pressed.
          </p>
          <p>
            Selecting this will prompt you to input the price of each card and
            then the quantity of gift cards at that price. The card can be
            swiped, if left blank it will be scanned by the payment terminal.
          </p>
          <p>Hit the OK button.</p>
          <img
            src={card_sale}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <p>
            The button color will change to confirm the transaction has been
            entered. Each card will be validated as they are entered.
          </p>
          <div className="d-flex">
            <img
              src={card_sale_icon1}
              alt=""
              placeholder="blur"
              className="img-physical-card-sm mr-5"
            />
            <img
              src={card_sale_icon2}
              alt=""
              placeholder="blur"
              className="img-physical-card-sm ml-5"
            />
          </div>
          <p>
            Once the gift card data has been entered, you can submit the
            transaction for payment from the POS system.
          </p>
          <p>
            From the POS, send the transaction to the payment terminal as a
            debit/credit transaction.
          </p>
          <p>
            Once the payment has been made, a confirmation message and receipt
            details will be sent to the POS system.
          </p>
          <h3 className="margin-top-30">Redeem Card</h3>
          <p>
            This option grants the ability to redeem the gift card and this
            transaction will appear in the pending transactions list. Selecting
            this will prompt you to input the amount that needs to be redeemed
            and the gift card number. Hit the OK button.
          </p>
          <p>
            Once this is pressed, Paymentree for windows has a “Gift” tender
            queued. When a credit/debit request is sent to Paymentree for
            windows, it will recognize the queued redemption and apply that to
            the credit/debit request.
          </p>
          <img
            src={redemption}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <h3 className="margin-top-30">Gift Card Balance</h3>
          <p>
            This option grants the ability to see a gift card’s balance.
            Selecting this will prompt you to input the gift card number. Hit
            the OK button.
          </p>
          <img
            src={card_number}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <p>A pop-up box titled “Balance” will appear showing the balance.</p>
          <img
            src={card_balance}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <h3 className="margin-top-30">View Pending Transactions</h3>
          <p>
            Whether a gift card is queued up to be sold or redeemed, the list of
            transactions would appear here. Selecting the red x button would
            remove the corresponding gift card from the queue.
          </p>
          <img
            src={pending}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <h3 className="margin-top-30">Clear Pending Transactions</h3>
          <p>
            Selecting this option will clear anything that’s in the “pending
            transactions” list
          </p>
          <img
            src={clear_queue}
            alt=""
            placeholder="blur"
            className="img-physical-card"
          />
          <img
            src={pending_2}
            alt=""
            placeholder="blur"
            className="img-physical-card margin-top-30"
          />
          <img
            src={sale_complete}
            alt=""
            placeholder="blur"
            className="img-physical-card margin-top-30"
          />
          <p>
            No matter which gift card you have selected, the promo and digital
            gift cards offered by Transactall can also be used concurrently. All
            these gift cards can be redeemed on the Android based payment
            terminal. See the other options on the Gift Card tab.
          </p>
        </section>
      </main>
    </div>
  );
};

export default ThirdPartyGiftCards;
