import React from "react";

import categories from "../images/categories.png";
import home from "../images/home.png";
import images from "../images/images.png";
import keywords from "../images/keywords.png";
import message from "../images/message.png";
import links from "../images/links.png";
import terminals from "../images/terminals.png";
import Breadcrumb from "./Breadcrumb";

const MerchantAccount = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="overview" className="space-y-4 mb-8">
          <h3 className="mb-2">Merchant Account</h3>
          <p>
            IDBillfold.com is a database repository for storing information
            about merchants. Here a merchant can manage their listing on
            GiveOrGo.com. Each merchant is assigned a unique user ID linked to
            their email address and will be password protected.
          </p>
          <p>
            A merchant account can only be created by a POS dealer or by
            connecting to info@paymentree.ca. A user ID and password will be
            provided for the merchant.
          </p>
          <p>
            Once created, logging in to www.IDBillfold.com will allow the
            merchant to set up their company information and images, and the
            marketing page will be automatically generated.
          </p>
          <p>
            Once logged in, the email address and password should be changed.
          </p>
        </section>

        <section id="merchant" className="space-y-4 mb-8">
          <h3>Test Account</h3>
          <p>
            For the email address enter the email as name@paymentree.ca where
            name should identify you and paymentree.ca lets the system know that
            this is a test account.
          </p>
        </section>
        <section id="home" className="space-y-4 mb-8">
          <h3>Home</h3>

          <img
            src={home}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            After creating an account and logging into IDBillfold you will be
            shown the home section of the profile. Use this section to add
            general address details and contact information for the business as
            it will appear in your marketing page.
          </p>
          <p>
            The Publish flag should be set once you have completed your setup to
            put the page into production on the web site.
          </p>
        </section>

        <section id="Categories" className="space-y-4 mb-8">
          <h3>Organizations</h3>
          <p>
            The merchant can select their franchisor, company from a list
            created by the dealer.
          </p>
          <p>
            Associations will be selected from a dropdown list of available
            associations.
          </p>
          <h3>Categories</h3>
          <img
            src={categories}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The Categories section can be used to describe the products and
            services being offered by the merchant. Categories are broken down
            into Types having various Attributes. A merchant can have multiple
            categories associated with their profile. The selections made on
            this tab generally affect how the merchant appears in GiveOrGo
            search results.
          </p>
        </section>

        <section id="Keywords" className="space-y-4 mb-8">
          <h3>Keywords</h3>

          <img
            src={keywords}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The Keywords section of the profile allows merchants to add
            searchable keywords to their listing that identify or highlight the
            products and services being offered.
          </p>
        </section>

        <section id="Links" className="space-y-4 mb-8">
          <h3>Links</h3>

          <img
            src={links}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The Links section of the profile allows merchants to list all of
            their social media, promotional, and e-commerce site links. Contact
            information can also be provided in this section.
          </p>
        </section>
        <section id="Message" className="space-y-4 mb-8">
          <h3>Message</h3>

          <img
            src={message}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The message section allows merchants to provide a slogan and
            marketing message for their GiveOrGo listing. Font and text editing
            capabilities are provided via a mini text editor.
          </p>
        </section>
        <section id="imgs" className="space-y-4 mb-9">
          <h3>Images</h3>

          <img
            src={images}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The images section allows for the uploading of product images which
            will appear in the Give or Go listing.
          </p>
        </section>
        <section id="imgs" className="space-y-4 mb-9">
          <h3>Terminals</h3>

          <img
            src={terminals}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />

          <p>
            The terminals section allows you create a list of terminals to setup
            for your store. Please see the pathway direct tab for more
            information.
          </p>
        </section>
        <section id="imgs" className="space-y-4 mb-9">
          ********************
          <p>
            This account will allow the merchant to utilize the E-commerce
            system to generate tokens to be used for transactions. By accessing
            the customer's account, the merchant will be able to query to see if
            any tokens already exist for this merchant or this customer (click
            on the E-Commerce tab above).
          </p>
          ********************
        </section>
      </main>
    </div>
  );
};

export default MerchantAccount;
