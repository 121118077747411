import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({ ...props }) => {
  let location = useLocation();
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState(
    location.pathname.split("/")
  );

  useEffect(() => {
    const currentLoc = location.pathname.split("/");
    setCurrentBreadcrumb(currentLoc);
  }, [location]);

  return (
    <div
      className="text-sm breadcrumbs flex flex-row justify-start mt-2"
      id={props.id}
    >
      <ul>
        {currentBreadcrumb[1] === "" ? (
          <li>
            <Link to="/">HOME</Link>
          </li>
        ) : (
          currentBreadcrumb.map((path) => {
            return (
              <li key={path}>
                <Link to={"#"}>
                  {path === ""
                    ? "HOME"
                    : path.toUpperCase().replaceAll("-", " ")}
                </Link>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default Breadcrumb;
