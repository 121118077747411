import React from "react";
import Tabs from "./Tabs";
import PaymentFlow from "./PaymentFlow";
import Breadcrumb from "./Breadcrumb";

const TablePay = () => {
  const tabContent = [
    {
      title: "PAYMENT FLOW",
      content: <PaymentFlow />,
    },
    // {
    //   title: "DEMONSTRATION",
    //   content: "This is the content of Tab 2.",
    // },
  ];
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <Tabs tabContent={tabContent} />
      </main>
    </div>
  );
};

export default TablePay;
