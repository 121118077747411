import React from "react";
import styles from "../styles/Giftcard.module.scss";
import enter_email from "../images/enter_email.png";
import enter_password from "../images/enter_password.png";
import gc_detail from "../images/gc_detail.png";
import cart_icon from "../images/cart_icon.png";
import shopping_cart from "../images/shopping_cart.png";
import amount_due from "../images/amount_due.png";
import confirm_email from "../images/confirm_email.png";
import payment_details from "../images/payment_details.png";
import pymt_summary from "../images/pymt_summary.png";
import purchase_response from "../images/purchase_response.png";
import purchase_confirm from "../images/purchase_confirm.png";
import Breadcrumb from "./Breadcrumb";


const Giftcard = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">

                <section id="overview" className="space-y-4 mb-8">
                    <h3 className="mb-2">Digital Gift Cards</h3>
                    <p className="mb-2">Digital Gift Cards can be purchased by selecting the Buy a Gift Card tab on a merchants marketing page at GiveOrGo.com. This functionality can also be called from a merchant&apos;s web store or from a browser based application.</p>

                    <p>Digital gift card processing capabilities do not require any programming. Even if the merchant is already using an existing gift card like Givex, these digital gift cards can be processed at the same time.</p> 

                    <p>Once the gift card purchase information has been entered and the credit card processed, an email will be sent to the customer. If the customer denies the purchase or no response is received within 1 hour the sale is cancelled and the credit card transaction is voided</p>

                    <p>If the customer confirms the purchase, the gift card is created and the balance updated.  An email is sent to the customer containing the QR code for the card. The QR code can be utilized during redemption of the card by presenting the phone image to the camera on the POS payment terminal.</p>

                    <p>All transactions are processed via a multi-use token, provided by the payment processor, which is stored in our secure database along with the email address. This token can be used for any future purchases instead of the credit card number.</p>

                    <p>Customers purchasing a gift card are encouraged to go to idbillfold.com and create a password protected personal account for their email address. This account provides many benefits to the customer, one of which is a user changeable PIN to future protect their data. If a PIN does exist, it will be prompted for at both the sale and redemption point.</p>

                    <p>Details of both the sale and redemption of these gift cards will be recorded in the customers personal account created in IDBillfold.com (click on the IDBillfold tab above).</p>


                </section>

                <section id="redemptions" className="space-y-4 mb-8">
                    <h3 className="mb-2">Gift Card Redemptions</h3>
                    <h5 className="mb-2">The QR code can be utilized during redemption of the card by presenting the phone image to the camera on the POS payment terminal</h5>

                    <p>
                        Following is a description of the process for purchasing a gift card.
                    </p>

                </section>

                <div className={ styles.containerImages }>
                    <section id="buying_gc" className="space-y-4 mb-8">
                        <h4>Buying a Gift Card</h4>           

                        <p className="mb-8">To buy a gift card, click on the "Buy Gift Card" button on the Merchant's Listing.</p>
    
                        <div>
                            <img 
                                src={ enter_email }
                                alt=""
                                placeholder="blur"
                                className={ styles.gcImg }
                            />
                        </div>

                        <p>To start your process, enter your email address then press the next button.</p>

                        {/* <h5>Account Password</h5>            */}
    
                        <img 
                            src={ enter_password }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>If you have an existing IDBillfold customer account, you will be prompted for the password. If no account exists, this password screen will not appear.</p>

                        {/* <h5>Gift Card Detail</h5>            */}
    
                        <img 
                            src={ gc_detail }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p className="block space-y-3"> Use this form to provide detail regarding gift card(s) you wish to purchase, as follows:</p> 
                            <ul className="space-y-3">
                                <li><span className="font-semibold">Amount:</span> Use this field to specify the dollar amount of the gift card.</li>
                                <li><span className="font-semibold">Quantity:</span> Use this field to specify the quantity of gift card (at the above specified dollar amount).</li>
                                <li><span className="font-semibold">When To Send:</span> If you wish, you can specify a future date for the purchased gift card to be sent.</li>
                                <li><span className="font-semibold">Send To Email:</span> Specify the email address where the gift card will be sent.</li>
                                <li><span className="font-semibold">Message:</span> A personal message can be specified in this field for the gift card recipient.</li>
                            </ul>
                        <p className="block space-y-3">After completing the form, click the Add Button to add the gift card to your cart. If you have additional cards to buy, you can fill out the form again, then click add. Repeat this process until you have all of the cards added to your cart.  </p> 

                        {/* <h5>Shopping Cart</h5>            */}
    
                        <img 
                            src={ cart_icon }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>After inputting all cards you wish to purchase, click the shopping cart icon to checkout.</p>

                        {/* <h5>Checkout</h5>            */}
    
                        <img 
                            src={ shopping_cart }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>If you wish to make changes or add a card, click the back button.</p>

                        <p>If the details are correct, click the Pay button.</p>

                        {/* <h5>Checkout: Amount Due</h5>            */}
    
                        <img 
                            src={ amount_due }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>You will be presented with another screen showing your amount due. Click the Next button.</p>

                        {/* <h5>Confirm Email</h5>            */}
    
                        <img 
                            src={ confirm_email }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>After confirming your email, click the Next button to proceed.</p>

                        {/* <h5>Payment Details</h5>            */}
    
                        <img 
                            src={ payment_details }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>You will be presented with a window to enter your credit card info. If you have an existing account, the software will check to see if you have a token.</p>

                        <p>Your credit card will be given a token which may be used for future purchases.</p>

                        {/* <h5>Payment Summary</h5>            */}
    
                        <img 
                            src={ pymt_summary }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>Click the Submit button to complete your transaction.</p>

                        {/* <h5>Payment Response</h5>            */}
    
                        <img 
                            src={ purchase_response }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>Once submitted, you will receive a payment response.</p>

                        <h5>Transaction Confirmation</h5>           
    
                        <img 
                            src={ purchase_confirm }
                            alt=""
                            placeholder="blur"
                            className={ styles.gcImg }
                        />

                        <p>If you click on create account, you will be redirected to IDBillfold (see IDBillfold tab above).</p>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default Giftcard;