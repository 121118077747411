import React from "react";
import { Link } from "react-router-dom";

const NavSubmenu = ({ items, activeItem, onClick }) => {
  return (
    <ul className="p-2 bg-slate-50 submenu">
      {items.map((item) => (
        <li
          key={item.path}
          className={activeItem === item.path ? "active" : ""}
        >
          <Link to={item.path} onClick={onClick}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavSubmenu;
