import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ path, label, dropdown, children, onClick, vertical }) => {
  return (
    <>
      {dropdown ? (
        <li tabIndex={0}>
          <Link className="justify-between">
            {label}
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d={
                  vertical
                    ? "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                    : "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                }
              />
            </svg>
          </Link>
          {children}
        </li>
      ) : (
        <li>
          <Link to={path} onClick={onClick}>
            {label}
          </Link>
        </li>
      )}
    </>
  );
};

export default NavItem;
