import React from "react";
import Breadcrumb from "./Breadcrumb";

const Pathway = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <h3 className="mb-2">Pathway</h3>

                    <p>
                        Pathway offers a simplified and more efficient method for implementing mobile solutions, both Wi-Fi and cellular, for the restaurant environment.
                    </p> 

                    <p>
                        Android based payment terminals can be preconfigured, shipped to a location, and simply turned on without requiring setup or router configuration.  These terminals will automatically establish a connection to the Pathway server.
                    </p>

                    <p>
                        These terminals can be configured for a default setting of Wi-Fi but will seamlessly switch to cellular should the Wi-Fi signal not be available.  They can also be set to be permanently in either state.
                    </p>

                    <p>
                        No programming is required if the location has a Paymentree installation as the communications to the Pathway server will be performed by this installation.
                    </p>

                    <p>
                        If however, the POS software programs the communications directly to the Pathway server, then no Paymentree installation is required at the location level.
                    </p>

                    <h4>
                        Note:
                    </h4>

                    <ul className="list-inside list-decimal">
                        <li>
                            This service can also be used by any cloud-based POS software which has a browser-based POS at each location.  Just configure and deliver the terminal, turn it on, and you are in business.
                        </li>

                        <li>
                            When used as a delivery payment terminal there is no need to configure it to a fixed IP address or to make port forwarding settings on the router.
                        </li>
                    </ul>
                </section>
            </main>
        </div>
    )
}

export default Pathway;