import React from "react";
import Breadcrumb from "./Breadcrumb";

const PaymentreePayments = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="introduction" className="space-y-4 mb-8">
          <h3 className="mb-2">Paymentree</h3>
          <p>
            Paymentree delivers a single, universal interface for all payment
            processing needs. Once you have certified to Paymentree, no further
            programming or certification is required to use the payment
            processor/payment terminal of your choice.
          </p>
          <p>
            All transactions received by Paymentree are routed to the payment
            provider whether debit/credit, gift card, cash, or require an
            e-commerce connection for services such as token on file.
          </p>
          <p>
            Additional functionality such as the selling of gift cards, tips,
            and donations are integrated with the POS back end.
          </p>
          <p>
            Every transaction, even those produced in a Solitaire (stand-alone)
            situation, are detailed in the Merchant Reports Portal available
            on-line.
          </p>
          <p>
            Each merchant also gets a free listing in the GiveOrGo.com
            marketplace web site where they can promote their products and
            services. From here they can redirect potential customers to their
            web site or web store as well as sell digital gift cards.
          </p>
          <p>
            A management portal is provided for dealers to order licenses,
            receive notices, and monitor the number of payment terminals that
            their merchants have in play.
          </p>
        </section>
      </main>
    </div>
  );
};

export default PaymentreePayments;
