import React from "react";

const Ecommerce = () => {
    return (
        <div>
            <main className="container mx-auto mb-8 py-8 px-8">
                {/*
                    <Image 
                        src={ idbf_logo }
                        alt="Merchant Account Homepage"
                        placeholder="blur"
                        style={ logoStyle }
                    />
                */}

                <section id="overview" className="space-y-4 mb-8">
                    <h3 className="mb-2">E-Commerce</h3>

                    <p>Transactall Inc. offers a fully integrated E-Commerce solution utilizing the Paymentree Inc. debit/credit ecosystem.</p> 

                    <p>Any merchant wishing to use the Transactall Inc. ecommerce system can go to giveorgo.com and from the landing page create a merchant account in IDBillfold.com (click on the IDBillfold tab above).</p> 

                    <p>All E-Commerce payments utilize a token generated by the merchant&apos;s payment processor to replace the customer&apos;s credit card number. Tokens can be requested via a secure (iframe) form along with the email address from a browser app or from the payment terminal with a card present request. A new token will be generated for a credit card each time it is used.</p>

                    <p>******iframe<br/>Must be manually input, all fields are mandatory.</p>

                    <p>As tokens are created by the merchant&apos;s payment processor, they can only be used by that processor. Transactall does however offer an association token that can be shared by various members of an association or group. See the billing section below.</p> 

                    <p>Each time a token is requested a database record is maintained for it in the email account requesting the token. If no account exists, then one will be created. Customers can personalize this account by adding a password in IDBillfold.com</p> 

                    <p>Merchants may query this database for their customers and use the associatedtokens as a replacement for card on file systems. This will put merchants utilizing this system into PCI compliance.</p>

                    <p>When a payment is initiated by the token, the payment amount will be processed against the customers credit card as an E-Commerce transaction.</p>

                    <p>If the payment type is direct, the funds are deposited into the merchants account at settlement time the same as if it was a credit card transaction. The merchant will receive a montly billing statement from Transactall which will include a surchard for the token transaction. The customer&apos;s credit card statement will display the merchant&apos;s name.</p>

                    <p>If the payment type is association, the funds will be deposited into a Transactall account at time of settlement. The funds will then be transferred to the merchants account, less a transaction fee. The transaction fee will include both the payment processing fee and the token surcharge. When the transaction is processed on the payment terminal, both a net amount tender record and a token surchare tender will be sent to the POS system. A monthly billing statement will be sent reflecting these transactions. The customer&apos;s credit card statement will display both Transactall and the merchant&apos;s name.</p>
                </section>
            </main>
        </div>
    );
}

export default Ecommerce;