import React from "react";
import Breadcrumb from "./Breadcrumb";

const Lodgingo = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">

                    <p>Lodgingo is a fully integrated payments application for the lodging industry from the reservation to checkout and beyond.  Combining the fully integrated debit/credit payment processing terminals offered by Paymenntree with the tokenized e-commerce payment solutions from Transactall, the functionality offered by high end PMS solutions can now be delivered by your reservation system.</p> 

                    <p>No jumping back and forth between your stand-alone payment terminals, your application to process token payments, and your reservation system to keep everything synchronized.  All payment requests are just sent to the payment terminal which routes them to the appropriate place.</p>

                    <p>And the payment terminals are easier to install then stand-alone units.  No need for router config, port forwarding, etc.  Just preconfigure the terminal, ship it to the lodging entity, and turn it on.  Our Pathway server will take  care of the rest.  Even if you require a 4G mobile connection.</p>

                    <p>Based on tokenization of the customer's credit card, the "Card On File" system is eliminated and the lodging entity can be brought into PCI compliance. </p>

                    <p>Your PMS solution can securely generate a token at reservation time and have it stored in our IDBillfold vault for future billing requirements. If you use reservation systems such as Expedia and process that card data through a PCI-DSS secure system, that card data can be converted to a token.</p>

                    <p>Once the token is available, a pre-auth transaction can be performed to secure the funds for contactless check in, no show and after checkout billing. In addition, pre-paid reservations can be collected immediately. At checkout the customer data can be queried to see if the payment will be made by token or with the physical card at the integrated payment terminal.</p>

                    <p>And the best part is you get your funds immediately.  We deal only with your payment processor for both payment terminal or e-commerce transactions.   If your reservation is prepaid, even from a booking agent like Expedia, There is no waiting for a virtual gift card to be enabled.</p>

                    <p>Implementing the Lodgingo application into a PMS system is straight forward as all the complex code is performed by the available API.</p>

                    <p>Basically, just passing parameters to our function calls. Both merchant and customer data are securely stored in our IDBillfold vault at our data center site.</p>
                </section>
            </main>
        </div>
    )
}

export default Lodgingo;