import React, { useState } from "react";

const Tabs = ({ tabContent }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="w-full mt-3">
      <div className="flex max-w-sm">
        {tabContent.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`w-full py-2 px-4 text-left ${
              activeTab === index
                ? "text-blue-500"
                : "text-black-500 hover:text-blue-500"
            }`}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-4 bg-white">{tabContent[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
