import React from 'react';
import Breadcrumb from './Breadcrumb';

const Home = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <p>
                        The Transactall ecosystem offers products fully integrated with Paymentree’s debit/credit payment solutions.  The dealer channel can market these products to their merchants to enhance their retail offerings.  Click on the various product tabs above.
                    </p>

                    <p>
                        Any merchant wishing to use the Transactall ecommerce system can create an account in IDBillfold.com   In addition to securely storing banking information to be used in the payment system, this account will provide the merchant with a free marketing page to promote their products or services. Click on the IDBillfold tab above.
                    </p>

                    <p>
                        Customers can go to GiveOrGo.com, search for the merchant of their choice, and be directed to the merchants marketing page. From the marketing page, customers can purchase a gift card or be directed to the merchant’s other sites such as a web store or web site.  Click on the GiveOrGo or Gift Card tabs above.
                    </p>

                    <p>
                        All e commerce transactions, such as purchasing a digital gift card, utilize a securely generated token by the merchant’s payment processor to replace the customer’s credit card number This token information is securely stored for each customer in our idbillfold.com database.  Click on the IDBillfold tab above.
                    </p>

                    <p>
                        Merchants may query this database for their customers and use the associated tokens as a replacement for card on file systems bringing them into PCI compliance. This customer database can also be used for future marketing campaigns.(click on the Ecommerce tab above).
                    </p>
                </section>
            </main>
        </div>
    );
}

export default Home;