import React from "react";
import Breadcrumb from "./Breadcrumb";
const ManagementPortal = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <h3 className="mb-2">Management Portal</h3>
        <embed
          src="/paymentreeNewPortalGuide.pdf"
          width="100%"
          height="500px"
        />
      </main>
    </div>
  );
};

export default ManagementPortal;
