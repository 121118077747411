import React from "react";
import NavItem from "./NavItem";
import NavSubmenu from "./NavSubmenu";

const NavMenu = ({
  onResponsiveMenuClick,
  onNavItemClick,
  className,
  vertical,
}) => {
  return (
    <ul tabIndex={0} className={className} onClick={onResponsiveMenuClick}>
      <NavItem path="/" label="Home" onClick={onNavItemClick} />
      <NavItem
        label="Paymentree"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/paymentree/payments", label: "Payments" },
            {
              path: "/paymentree/management-portal",
              label: "Management Portal",
            },
            {
              path: "/paymentree/tablepay",
              label: "TablePay",
            },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
      <NavItem
        label="IDBillfold"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/idbillfold/customer", label: "Customer Account" },
            { path: "/idbillfold/merchant", label: "Merchant Account" },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
      <NavItem path="/giveorgo" label="GiveOrGo" onClick={onNavItemClick} />
      <NavItem
        label="Gift Cards"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/giftcard/physical", label: "Physical Gift Cards" },
            { path: "/giftcard/digital", label: "Digital Gift Cards" },
            { path: "/giftcard/promo", label: "Promo Gift Cards" },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
      <NavItem
        label="Solitaire"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/solitaire/standAlone", label: "Stand Alone" },
            { path: "/solitaire/backup", label: "Backup" },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
      <NavItem
        label="E-Commerce"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/ecommerce/payments", label: "Payments" },
            { path: "/ecommerce/token-on-file", label: "Token on File" },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
      <NavItem path="/lodgingo" label="Lodgingo" onClick={onNavItemClick} />
      <NavItem
        label="Comms"
        dropdown={true}
        vertical={vertical}
        onClick={onNavItemClick}
      >
        <NavSubmenu
          items={[
            { path: "/comms/android-direct", label: "Android Direct" },
            { path: "/comms/pathway", label: "Pathway" },
          ]}
          onClick={onNavItemClick}
        />
      </NavItem>
    </ul>
  );
};

export default NavMenu;
