import React from "react";

import styles from "../styles/Giveorgo.module.scss";
import search_homepage from "../images/search_homepage.png"; 
import location_parameters from "../images/location_parameters.png";
import gift_promo_webstore from "../images/gift_promo_webstore.png";
import company_search from "../images/company_search.png";
// import company_search_button from "../images/company_search_button.png";
// import enter_name from "../images/enter_name.png";
// import keyword_search from "../images/keyword_search.png";
// import enter_keywords from "../images/enter_keywords.png";
import keyword_results from "../images/keyword_results.png";
import category_search from "../images/category_search.png";
import category_search_classes from "../images/category_search_classes.png";
import category_search_attributes from "../images/category_search_attributes.png";
import select_multiple from "../images/select_multiple.png";
import category_results from "../images/category_results.png";
import back_button from "../images/back_button.png";
import Breadcrumb from "./Breadcrumb";



const Giveorgo = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">

                <section id="search" className="space-y-4 mb-8">

                    <p>GiveOrGo.com is a marketplace application where merchants can create a free marketing page to attract potential customers for their goods or services.</p> 
                </section>

                <section id="redemptions" className="space-y-4 mb-8">
                    <h4 className="mb-2">Search page and description</h4>

                    <p>Potential customers can find merchants by utilizing an easy to use, search engine designed specifically for the retail environment.  Once selected, customers can view the merchants marketing message and can also be redirected to the merchant&apos;s web site or web store.</p>

                    <p>Digital gift cards can be purchased by clicking on the Buy Gift Card button (click on the Gift Card tab above).  Once the gift card details have been entered, payment is made through the Transactall.com payment screen utilizing a secure Iframe input form.  If the customer already has a token with the selected merchant, it can be used and no credit card information is required (click the E-Commerce tab above). </p>

                    <p>To view a sample merchant marketing page:</p>
                        <ul className="list-decimal list-inside">
                            <li>From the Homepage select search by Category.</li>
                            <li>Click on Department Restaurant.</li>
                            <li>Click on Class Casual Dining.</li>
                            <li>Select Food as Italian.</li>
                            <li>Click on Search button.</li>
                            <li>Click on the restaurant of your choice.</li>
                        </ul>

                    <p>This will display a typical marketing page.</p>
                    
                </section>

                <section id="search-homepage" className="space-y-4 mb-8">
                    <h4>Search Homepage</h4>           

                    <img 
                        src={ search_homepage }
                        alt="Search Homepage"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>The Search tool located on the Homepage of Give Or Go, allows you to locate any merchants in our database which match various search criteria.</p>
                </section>

                <section id="limit_search" className="space-y-4 mb-8">
                    <h4>Limit Search</h4>           
                    <h5>Location</h5>           

                    <img 
                        src={ location_parameters }
                        alt="Location Parameters"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>Clicking the Location button reveals several location-relaed fields for your search (country, province, city, and postal code). It is not mandatory to complete all of these location fields. Fill out any of the fields, that you feel are necessary for your search.</p>

                    <h5>Gift Card, Promo, & Webstore Merchants</h5>           

                    <img 
                        src={ gift_promo_webstore }
                        alt="Limit Searches: Gift Card, Promo, Webstore"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>If desired, you can restrict your search to only include merchants in your search results offering Gift Cards. You can also choose to only include merchants in your search results offering promotions. In addition, you can choose to exclude merchants from your search results if they do not have a webstore.</p>
                </section>

                <section id="company-search" className="space-y-4 mb-8">

                    <h4>Company Search</h4>           



                    <img 
                        src={ company_search }
                        alt="Company Search"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />
                    <p>Click on the "Company" button on the Search Homepage to bring up the Company Search field. Next, to search by Company Name you must enter the full or partial name of the Merchant. Then click Search. Any matches found will be returned in a list. Click on any of the returned search results to see more detail for the specific Merchant. You will be directed to the Merchant's listing in Give Or Go.</p>
                </section>

                <section id="keyword-search" className="space-y-4 mb-8">

                   <h4>Keyword Search</h4>           

                   <img 
                        src={ keyword_results }
                        alt="Keyword Search Results"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>To search by keywords, enter one or more words, seperated by a comma. Then click search. Any Merchants with the keywords contained within their listing will be returned in the results list. Click on any of the returned results to see more detail.</p>
                </section>

                <section id="Category Search" className="space-y-4 mb-8">

                    <h4>Category Search</h4>           

                    <img 
                        src={ category_search }
                        alt="Category Search"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>To begin a Category search, select your area of interest from the list of options presented.</p>

                    <img 
                        src={ category_search_classes }
                        alt="Category Search: Classes"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>After selecting an option, you are presented with another list of options to further refine your search.</p>

                    <img 
                        src={ category_search_attributes }
                        alt="Category Search: Attributes"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>After selecting a Class option, a final list of options for your seach appears.</p>

                    <img 
                        src={ select_multiple }
                        alt="Select Multiple Attributes"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>From this list, you are able to select multiple options. These attributes will exclude any merchants from your search results who do not offer all of the selected services.</p>

                    <img 
                        src={ category_results }
                        alt="category_results"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>After clicking the Search button, a list of results will appear to the right. You can click on any of the returned results. You will be directed to the Merchant's listing page.</p>

                    <img 
                        src={ back_button }
                        alt="Back Button"
                        placeholder="blur"
                        className={ styles.gogImg }
                    />

                    <p>To return to your search results, click the Back button. You will be able to modify and run the search again.</p>
                </section>
            </main>
        </div>
    );
}

export default Giveorgo;