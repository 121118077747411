import React from 'react';
import Breadcrumb from './Breadcrumb';

const PromoGiftCards = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <h3 className="mb-2">Promo Gift Cards</h3>

                    <p>
                        Promotional gift cards can be preloaded with a dollar amount and emailed to potential customers, provide a gift to loyal customers, or used as any incentive that you wish to track to analyze to determine the effectiveness of a campaign.
                    </p> 

                    <p>
                        Email blasts are defined as campaigns and have start and end dates.  The campaign can be preloaded but will not be sent out until its start date.   Campaigns automatically end on their expiry date.
                    </p>

                    <p>
                        A list of email addresses, customer names, and amounts can be supplied as a csv file and the email blast will be automatically generated.
                    </p>

                    <p>
                        Each email address will also generate a new email account if one does not yet exist in the IDBillfold database.  This data will be used for analysis purposes.  Follow up email promo cards can be sent to all that replied to the initial campaign.
                    </p>

                    <p>
                        Customers can put passwords on these accounts and be able to track their gift card purchases, e-commerce purchases, and “card on file” transactions.The next release of this product will allow for campaigns to be initiated by using loyalty data in the customer file.  
                    </p>

                    <p>
                        Click on the IDBillfold tab for more information on the customer account.
                    </p>
                </section>
            </main>
        </div>
    );
}

export default PromoGiftCards;