import React from "react";

const PaymentFlow = () => {
  return (
    <div className="">
      <h1 className="text-2xl font-bold text-center mt-4 mb-8">
        Paymentree for Android Payment Processing
      </h1>
      <div className="flex justify-center">
        <a
          href="/payment-processing.pdf"
          download="Payment-processing.pdf"
          className="btn btn-sm bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 mb-5 rounded inline-flex items-center"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>Download PDF</span>
        </a>
      </div>
      <h2 className="text-xl font-bold mb-4">1. OVERVIEW</h2>
      <p className="mb-4">
        Paymentree for Android is a processor agnostic payment solution for the
        Cashier and Pay at the Table environments. It supports the ability to
        pay standard Credit & Debit, as well as provides integrated handling of
        third party Gift Card and Cash tenders. This document outlines how
        payments are handled, specifically for SALE transactions in the Pay at
        the Table environment.
      </p>

      <h2 className="text-xl font-bold mb-4">2. PAYMENT</h2>

      <h3 className="text-base font-bold mb-4">2.1 OVERVIEW</h3>
      <p className="mb-4">
        The payment will be paid in full before transactions are posted to the
        POS. The process of paying a check in full is often straightforward with
        a single method of payment. Other times the process can be more
        complicated, involving numerous payments of varying amounts and tender
        types. Paymentree for Android has been designed to handle these
        complicated scenarios with its revised payment flow.
      </p>
      <p className="mb-4">The Payment flow is broken down into six parts:</p>
      <ol className="list-decimal list-inside mb-4">
        <li>Pre-Payment</li>
        <li>Collecting Payments</li>
        <li>Allocate Gratuity & Donations</li>
        <li>Producing Receipts</li>
        <li>Completing Transaction for Suspended Sales</li>
        <li>Sending Transaction Results to the POS</li>
      </ol>

      <h3 className="text-base font-bold mb-4">2.2 PRE-PAYMENT</h3>

      <h4 className="text-sm font-bold mb-4">
        2.2.1 SELECTING PAYMENT OPTIONS
      </h4>
      <p className="mb-4">
        Once the server has selected the desired check, the payment process
        begins with the server prompting the customer for how they are going to
        pay the amount due (e.g., Credit, Cash, Gift Card etc.). The server will
        select those options and begin the Payment process on the terminal.
      </p>

      <h4 className="text-sm font-bold mb-4">2.2.2 TRANSACTION CONFIRMATION</h4>
      <p className="mb-4">
        After the server has selected the Payment method(s), the terminal will
        be passed to the customer, at which time they will review and confirm
        the transaction.
      </p>
      <p className="mb-4">
        If the transaction contains Included Gratuity, the confirmation screen
        will include the amount of gratuity included, and the total due. It will
        also display a prompt of “To add additional gratuity press &lt;IMAGE
        BUTTON&gt;”. If this button is pressed, the customer is taken to the
        Gratuity activity. If they press Next, if enabled the Donation activity
        is started otherwise proceed to COLLECTING PAYMENTS.
      </p>
      <p className="mb-4">
        If there is no included gratuity and if Gratuity is enabled, the
        customer will enter the Gratuity activity which will allow them to
        select the desired gratuity amount. If paying with cash, a zero amount
        can be accepted and a gratuity amount can be adjusted at the end of the
        sale from the change due amount. Next, if Donation is enabled, they will
        select the desired donation amount.
      </p>
      <p className="mb-4">
        If either a Gratuity, Additional Gratuity or Donation amount was
        entered, a final confirmation is displayed showing the original amount,
        any amounts added, and the total amount to pay.
      </p>
      <p className="mb-4">
        If the customer has entered a gratuity or donation amount, the server
        will be prompted to confirm the amounts before proceeding to the
        Collecting Payments activity.
      </p>
      <h2 className="text-base font-bold mb-4">2.3 COLLECTING PAYMENTS</h2>
      <p className="mb-4">
        Because Paymentree for Android supports many payment providers as well
        as gift card processing, transaction requests sent will be combined
        amounts (sale amount + gratuity + donation) if multiple payment methods
        are selected. If a single tender method is selected, Gratuity will be
        passed to the processor. For multi-tenders, gratuity amounts will not be
        specified in the transaction request to the payment processor. Gratuity
        and Donation application and summation is done at the Applying
        Transaction Results to the POS and Producing Receipts stage. For those
        requiring Gratuity reporting, please use POS provided functionality, or
        you may also use Paymentree reporting to obtain gratuity reports.
      </p>
      <p className="mb-4">
        Payments are applied in the order of GIFT CARD redemptions, CASH, then
        CREDIT/DEBIT. If there is an issue with CREDIT (such as insufficient
        funds, partial approval etc.) the option of CASH will be made available.
      </p>
      <p className="mb-4">
        During the Payment process, the server may select the Suspend option.
        This will store any payments made to the Transaction service. When the
        check is recalled, the suspended Payment(s) will be recalled, applied to
        the check, and the Process will resume starting with SELECTING PAYMENT
        OPTIONS.
      </p>

      <h3 className="text-base font-bold mb-4">2.3.1 GIFT CARD PAYMENTS</h3>
      <p className="mb-4">
        Gift Card payments, if selected, are processed before other tender
        types. If the server indicated the customer is paying with one or more
        gift cards, the server will obtain the terminal from the customer and
        begins applying each gift card presented. If the transaction is paid in
        full with the presented gift card(s), the terminal will continue on to
        Allocating Gratuity & Donation. After the first Gift Card is processed,
        the server may select the GIFT CARD option and press Next to start
        another redemption. The server will repeat this process for each Gift
        Card presented. After processing all Gift Cards, if the transaction is
        not paid in full, the Payment process will proceed to the next selected
        payment method of Cash, or Credit/Debit.
      </p>

      <h3 className="text-base font-bold mb-4">2.3.2 CASH PAYMENTS</h3>
      <p className="mb-4">
        Cash payment, if selected, is processed by the server after all Gift
        Cards have been processed (if applicable). If the server indicated the
        customer is paying with Cash and does not yet have the terminal, the
        server will obtain the terminal from the customer and begin applying a
        Cash payment. The terminal will display the total amount due, along with
        a field to enter the amount being paid. The Payment Amount is default to
        zero, with a keypad available for amount entry.
      </p>
      <p className="mb-4">
        If Cash was initially selected, but the customer decides not to apply
        Cash, the amount is left as ZERO, and the server selects Next to proceed
        to Credit/Debit payment. If the amount applied is LESS than the amount
        due, the Cash payment is applied and proceeds to the Credit/Debit
        activity. If the amount applied was equal to the amount due, continue to
        Allocate Gratuity & Donation. If the amount exceeds the amount due,
        change due is calculated and displayed to the server. The server will
        present the customer with the change due before selecting Next.
      </p>
      <p className="mb-4">
        If paid in full or when the server returns with the change, if no
        gratuity was entered, prompt “Would the customer like to include
        gratuity on the receipt” is displayed. If the customer says Yes, the
        server will collect the gratuity, and enter the amount, and go to
        receipt printing. If the customer says No, then go to Receipt Printing.
        If gratuity is enabled and no gratuity was provided, all transactions
        will be suspended so that they may be recalled and gratuity added after
        the customer has departed. Otherwise, if gratuity was indicated the
        transactions will be immediately posted to the POS.
      </p>
      <h3 className="text-base font-bold mb-4">2.3.3 CREDIT/DEBIT PAYMENTS</h3>
      <p className="mb-4">
        The terminal is presented to the customer. A screen showing the amount
        due, with payment amount equal to the remaining balance. The Partial
        Payment button is available if the customer wishes to splitting balance
        between multiple cards, they may press this button to show keypad and
        enter desired amount. Available tender type is CREDIT/DEBIT and is
        preselected. If the transaction is a partial payment, or results in a
        partial approval (e.g., prepaid credit-branded gift cards), the result
        is displayed to the customer, and then they are presented with the same
        screen with updated amount due, except that this time they are shown
        tender options of paying with CREDIT/DEBIT and CASH (if enabled). They
        optionally may select the KEYBOARD option button to indicate another
        partial payment.
      </p>
      <p className="mb-4">
        Cash, when enabled and selected, allows a transaction to be paid in full
        in the event of a partial approval leaving a balance due. If Cash is
        selected, the customer is instructed to return the terminal to the
        server.
      </p>

      <h3 className="text-base font-bold mb-4">
        2.4 ALLOCATE GRATUITY & DONATION
      </h3>
      <p className="mb-4">
        Once the transaction is paid in full the customer is instructed to
        return the terminal to the server. If more than one payment was applied,
        the terminal performs a Gratuity and Donation allocation process prior
        to posting payments to the POS. By default, Donations are added to the
        Gratuity amount as most POS systems do not have a dedicated field to
        accept the Donation amount. If a transaction is paid in a single
        payment, the entire Gratuity + Donation will be applied. If there are
        more than one payments, the combined amount of Gratuity and Donation
        will be calculated as the overall percentage of the total bill and will
        be applied evenly distributed across all payments.
      </p>
      <p className="mb-4">
        Example, a sale is $100.00, customer adds $25.00 Gratuity for a total of
        $125.00. It is paid with two gift cards, and credit. The percentage of
        the Gratuity amount is calculated to be 20%. The payments applied to the
        POS would be as follows:
      </p>
      <table className="table-auto mb-4 w-full text-center">
        <thead>
          <tr>
            <th className="px-4 py-2">METHOD</th>
            <th className="px-4 py-2">PAYMENT</th>
            <th className="px-4 py-2">SALE</th>
            <th className="px-4 py-2">GRATUITY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">GIFT</td>
            <td className="border px-4 py-2">5.00</td>
            <td className="border px-4 py-2">4.00</td>
            <td className="border px-4 py-2">1.00</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">GIFT</td>
            <td className="border px-4 py-2">5.00</td>
            <td className="border px-4 py-2">4.00</td>
            <td className="border px-4 py-2">1.00</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">CREDIT</td>
            <td className="border px-4 py-2">115.00</td>
            <td className="border px-4 py-2">92.00</td>
            <td className="border px-4 py-2">23.00</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-base font-bold mb-4">
        2.5 SENDING TRANSACTION RESULTS TO THE POS
      </h3>
      <p className="mb-4">
        The terminal will begin sending transaction results to the POS in the
        order that they were processed, displaying a progress indicator to the
        server.
      </p>

      <h3 className="text-base font-bold mb-4">2.6 PRODUCING RECEIPTS</h3>
      <p className="mb-4">
        When a transaction is paid in full, if Gratuity is enabled but no
        Gratuity was specified by the customer, a prompt is displayed to the
        server “Would the customer like Gratuity reflected on the receipt” with
        options of YES/NO. If the customer says Yes, a Gratuity entry screen is
        displayed, the server collects and enters the amount, then selects Next.
        If the customer says NO (or indicates they do not want a receipt), the
        transaction will be suspended for later call.
      </p>
      <p className="mb-4">
        When a transaction is paid using one or more CASH and/or GIFT CARD
        tenders, or, the terminal is configured to use ENHANCED RECEIPTS, a
        consolidated transaction receipt will be generated. This will consist of
        the header with logo, transaction body listing transaction detail for
        each payment, transaction summary, followed by receipt footer.
      </p>
      <p className="mb-4">
        When using standard terminal receipts, credit transactions will be shown
        as the CARD TYPE, MASKED CARD #, AMOUNT. If there are no modifications
        (e.g., Gratuity, Donation etc.), the summary receipt will not be
        printed.
      </p>
      <p className="mb-4">
        When the terminal is configured for ENHANCED RECEIPTS, the receipt body
        will show detail for each payment applied, with all applicable receipt
        fields.
      </p>
      <p className="mb-4">
        Merchant Receipts will be produced as a consolidated receipt with all
        transaction detail regardless of whether using Terminal or Enhanced
        Receipts. Transaction summary is displayed below the transaction body if
        there is more than one transaction, or a Gratuity and/or Donation
        specified. The summary will show the original transaction total,
        Gratuity (if applied), Donation (if applied), Number of Payments
        applied. When using standard terminal receipts and there are no
        modifications (e.g., Gratuity, Donation etc.), the summary Customer
        receipt will not be printed.
      </p>

      <h3 className="text-base font-bold mb-4">2.7 SUSPENDED SALES</h3>
      <p className="mb-4">
        In a table with multiple customers, one or more individual may wish to
        leave early. This payment can be handled in two ways.
      </p>

      <h4 className="text-sm font-bold mb-4">2.7.1 SPLIT SALE</h4>
      <p className="mb-4">
        The server recalls the table and check, then applies a Split equal to
        the number of individuals at the table. Server will process one or more
        of the check Splits, which will allow for individual Tips and Donations
        and provide a receipt for each. After completing one or more, the server
        then may select the Suspend button. In this scenario, payments for each
        of the Splits are posted to the POS. Selecting the Suspend button allows
        the Server to exit the payment flow leaving the check open and allowing
        for additional items to be added. The Suspend option is not available
        while applying payments to an individual check Split, it must be paid in
        full.
      </p>

      <h4 className="text-sm font-bold mb-4">2.7.2 RECALLING A SPLIT SALE</h4>
      <p className="mb-4">
        On the recall of the check, the balance is returned from the POS. In
        this case, the server may split this amount for the remaining
        individuals. Each check split is processed as normal.
      </p>

      <h4 className="text-sm font-bold mb-4">2.7.3 PARTIAL PAYMENT</h4>
      <p className="mb-4">
        The server recalls the table and check, then starts payment. In this
        case, for example, a customer wishes to leave $50.00 towards the overall
        bill. The server will retain the terminal and enter zero for tip and/or
        donation and proceed to process the one or more partial payments for the
        amount due. This may be any tender, Cash, Credit/Debit or Gift Card.
        When all payment(s) have been completed, the server will select
        Suspended. This will prompt server to confirm, and then show the receipt
        screen. Transaction(s) are suspended to the cloud, not to the POS.
        Applying a partial payment will not allow a check to be split when
        Payment is resumed.
      </p>

      <h4 className="text-sm font-bold mb-4">
        2.7.4 RECALLING PARTIAL PAYMENT
      </h4>
      <p className="mb-4">
        When a server recalls an individual check, any payments previously made
        will be retrieved from the cloud and applied, which will update the
        balance due. The Sale Amount, Payments, and Balance Due will be
        displayed to the server. In this case, splitting is not available. The
        server will proceed to start Payment as normal, with prompting how the
        check is to be paid (Cash, Credit/Debit, Gift Card etc.). The terminal
        is passed to the customer and the Tip and Donation are entered. The
        payment process is continued until the bill is paid in full and the
        customer is prompted for receipt printing.
      </p>
      <p className="mb-4">
        When payment is received in full for a non-split Check, payments will be
        automatically suspended if tipping is enabled but no tip was indicated
        by the customer. This allows the Server to recall the check, and apply a
        Gratuity Adjustment after the customer has departed. To perform a
        Gratuity Adjustment, the Server will recall a Check, which will indicate
        it is paid in full. The server may select the option Gratuity Adjust
        which will present them with a Amount Entry screen, otherwise they may
        select Next to close the check without updating the Gratuity amount. If
        selecting Next, a confirmation will be displayed to ensure they wish to
        close the check without Gratuity.
      </p>
    </div>
  );
};

export default PaymentFlow;
