import React from 'react';
import Breadcrumb from './Breadcrumb';

const AndroidDirect = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <h3 className="mb-2">Payments</h3>

                    <p>
                        Paymentree is now offering a new option to software developers that have programmed to the Paymentree API.  If your merchants are using Android based terminals, you will be able to communicate directly with the terminal and not require the installation of the Paymentree software at a location.
                    </p>

                    <p>
                        Simply point your software connection to the IP address of the payment terminal instead of to the Paymentree software.  If the terminal is to be used for Table Pay, configure the terminal with the IP address of your POS host.
                    </p>

                    <p>
                        The format of the transactional data is sent as json instead of xml but is virtually identical.
                    </p>

                    <p>
                        The Android Direct functionality is now part of our standard Paymentree for Android offering at no additional cost.
                    </p>

                    <p>
                        Software developers that implement the Android Direct API can easily upgrade to the full Pathway service.
                    </p>

                </section>
            </main>
        </div>
    );
}

export default AndroidDirect;