import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Home";
import Giftcard from "./Giftcard";
import Giveorgo from "./Giveorgo";
import Ecommerce from "./Ecommerce";
import Navbar from "./Navbar";
import Lodgingo from "./Lodgingo";
import Pathway from "./Pathway";
import ThirdPartyGiftCards from "./ThirdPartyGiftCards";
import PromoGiftCards from "./PromoGiftCards";
import Payments from "./Payments";
import TokenOnFile from "./TokenOnFile";
import AndroidDirect from "./AndroidDirect";
import CustomerAccount from "./CustomerAccount";
import MerchantAccount from "./MerchantAccount";
import StandAlone from "./StandAlone";
import Backup from "./Backup";
import PaymentreePayments from "./PaymentreePayments";
import ManagementPortal from "./ManagementPortal";
import TablePay from "./TablePay";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/idbillfold/merchant"
          element={<MerchantAccount />}
        />
        <Route
          exact
          path="/idbillfold/customer"
          element={<CustomerAccount />}
        />
        <Route
          exact
          path="/paymentree/payments"
          element={<PaymentreePayments />}
        />
        <Route
          exact
          path="/paymentree/management-portal"
          element={<ManagementPortal />}
        />
        <Route exact path="/paymentree/tablepay" element={<TablePay />} />
        <Route exact path="/giveorgo" element={<Giveorgo />} />
        <Route exact path="/giftcard/digital" element={<Giftcard />} />
        <Route
          exact
          path="/giftcard/physical"
          element={<ThirdPartyGiftCards />}
        />
        <Route exact path="/giftcard/promo" element={<PromoGiftCards />} />
        <Route exact path="/solitaire/standalone" element={<StandAlone />} />
        <Route exact path="/solitaire/backup" element={<Backup />} />
        <Route exact path="/ecommerce" element={<Ecommerce />} />
        <Route exact path="/ecommerce/payments" element={<Payments />} />
        <Route
          exact
          path="/ecommerce/token-on-file"
          element={<TokenOnFile />}
        />
        <Route exact path="/lodgingo" element={<Lodgingo />} />
        <Route exact path="/comms/android-direct" element={<AndroidDirect />} />
        <Route exact path="/comms/pathway" element={<Pathway />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
