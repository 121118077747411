import React from "react";
import Breadcrumb from "./Breadcrumb";

const Backup = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="introduction" className="space-y-4 mb-8">
          <h3 className="mb-2">Solitaire – Backup</h3>
          <p>
            Solitaire provides an enhanced backup solution to the standard
            backup provided by the payment terminal in the case of a failure to
            communicate with the POS system.
          </p>
          <p>
            All the features of the stand-alone version except for the selling
            of GiveOrGo.com gift cards are available in the backup mode.
          </p>
          <p>
            This is especially relevant in the restaurant environment as the
            terminal will operate very similar to a Table Pay terminal.
          </p>
          <p>
            Both the server and check number can be entered for reporting
            purposes. The check can then be split if required. Tips and
            donations can be added and detailed on the receipt provided.
          </p>
          <p>
            All payment methods are available including cash and prepaid gift
            cards and are reported on in the Merchant Portal. Any number of
            partial payments can be applied to settle a check.
          </p>
          <p>
            All transactional data is available in the Merchant Portal and can
            be exported and used for integration with a back end system.
          </p>
        </section>
      </main>
    </div>
  );
};

export default Backup;
