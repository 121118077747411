import React, { useState } from "react";
import transactall_logo from "../images/transactall_logo.png";
import "./../styles/Navbar.scss";
import Breadcrumb from "./Breadcrumb";
import NavMenu from "./NavMenu";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleButton = () => setIsOpen(!isOpen);

  const removeActiveClass = () => {
    const activeNavItems = document.getElementsByClassName("active");
    const activeItemsArr = Array.from(activeNavItems);

    activeItemsArr.forEach((element) => {
      element.classList.remove("active");
    });
  };

  const onNavItemClick = (e) => {
    removeActiveClass();
    e.target.classList.add("active");
    e.target.parentElement.parentElement.parentElement.childNodes[0].classList.add(
      "active"
    );
  };

  const onResponsiveMenuClick = (e) => {
    if (e.target.parentElement.childNodes.length <= 1) {
      e.target.blur();
    }
  };
  return (
    <>
      <img
        src={transactall_logo}
        alt="Merchant Account Homepage"
        placeholder="blur"
        className="logo"
      />
      <div className="navbar px-2">
        <div className="dropdown">
          <button
            tabIndex={0}
            className="btn btn-ghost xl:hidden mr-4"
            onClick={toggleButton}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          {/* Small screen menu */}
          {isOpen && (
            <NavMenu
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
              onResponsiveMenuClick={onResponsiveMenuClick}
              onNavItemClick={onNavItemClick}
              vertical={true}
            />
          )}
        </div>
        {/* Large screen menu */}
        <Breadcrumb id="breadcrumbs-responsive" />
        <NavMenu
          className="menu menu-horizontal px-1 flex flex-row justify-between w-full"
          onResponsiveMenuClick={onResponsiveMenuClick}
          onNavItemClick={onNavItemClick}
          vertical={false}
        />
      </div>
    </>
  );
};

export default Navbar;
