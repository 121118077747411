import React from 'react';
import Breadcrumb from './Breadcrumb';

const TokenOnFile = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <h3 className="mb-2">Token On File</h3>

                    <p>
                        Transactall along with Paymentree offer a fully integrated, on Android payment terminals, replacement for Card On File systems bringing the merchant into PCI compliance.
                    </p>

                    <p>
                        Obtaining a token for a credit card can be accomplished by the merchant by filling in a secure web based form. This information is stored in our secure IDBillfold vault and can be used for any future purchase from that merchant.  Multiple credit cards can be stored.
                    </p>

                    <p>
                        When a purchase is made, a simple query will display what tokens are available for that customer.    A token can be sent or the transaction can be process as a debit/credit payment.  Token transactions will be processed by e-commerce or on the payment terminal, depending on the services provided by their token provider.
                    </p>

                </section>
            </main>
        </div>
    );
}

export default TokenOnFile;