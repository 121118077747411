import React from 'react';
import Breadcrumb from './Breadcrumb';

const Payments = () => {
    return (
        <div>
            <Breadcrumb />
            <main className="container mx-auto mb-8 py-8 px-8">
                <section id="transactall-lodging" className="space-y-4 mb-8">
                    <h3 className="mb-2">Payments</h3>

                    <p>
                        All E-Commerce payments utilize a token generated by the   merchant’s payment processor, to replace the customer’s credit card number.  Tokens can be requested via a secure (iframe) form along with the email address from a browser app or from the payment terminal with a card present request.  A new token will be generated for a credit card each time it is used.  As tokens are created by the merchant’s payment processor, they can only be used by that merchant and processor.
                    </p>

                    <p>
                        Each time a token is requested a record is maintained for it in the IDBillfold email account requesting the token.  If no account exists, then one will be created.  Customers can take ownership of this account by adding a verified password in IDBillfold.com.
                    </p>

                    <p>
                        When a payment is initiated by the token, the payment amount will be processed against the customers credit card and will appear that way on both the customers and merchants credit card statements.
                    </p>

                    <p>
                        The payments will be e-commerce transactions unless the payment processor allows for token transactions to be applied on their payment terminals.
                    </p>

                </section>
            </main>
        </div>
    );
}

export default Payments;