import React from "react";
import Breadcrumb from "./Breadcrumb";
import customerAccount from "../images/customer-account.png";

const CustomerAccountInfo = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="introduction" className="space-y-4 mb-8">
          <h3 className="mb-2">Customer Account</h3>
          <p>
            IDBillfold.com is a database repository for storing information
            about customers. Here a customer can create a free personal account
            to track and manage their tokens and gift card purchases.
          </p>
          <p>
            Each customer is assigned a unique user ID linked to their email
            address. They can then assign a password which will then be
            authenticated via an email reply.
          </p>
          <p>
            An account can be directly created at IDBillfold.com or from the
            Create Customer Account button on the GiveOrGo.com landing page. An
            account is also automatically created to store the token information
            whenever the E-Commerce system is used. See the link to E-Commerce.
          </p>
        </section>
        <section id="features" className="space-y-4 mb-8">
          <h3 className="mb-2">This account will allow you to:</h3>
          <ul className="feature-list">
            <li>View gift card transaction history.</li>
            <li>Check your gift card balances.</li>
            <li>Add funds to your gift card balances.</li>
            <li>Maintain a list of “Send To” email addresses.</li>
            <li>Resend gift cards that are inadvertently deleted.</li>
          </ul>
          <p>See the link to Gift Cards for more information.</p>
        </section>
        <section id="home" className="space-y-4 mb-8">
          <h3 className="mb-2">Home</h3>
          <p>
            After creating an account and logging into IDBillfold, you will be
            shown the home section of your profile.
          </p>
          <img
            src={customerAccount}
            alt=""
            placeholder="blur"
            className="img-blackShadow "
          />
          <p>
            In the home section, you have the ability to change the password or
            the email address.
          </p>
        </section>
      </main>
    </div>
  );
};

export default CustomerAccountInfo;
