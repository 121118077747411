import React from "react";
import Breadcrumb from "./Breadcrumb";

const StandAlone = () => {
  return (
    <div>
      <Breadcrumb />
      <main className="container mx-auto mb-8 py-8 px-8">
        <section id="introduction" className="space-y-4 mb-8">
          <h3 className="mb-2">Solitaire – Stand Alone</h3>
          <p>
            Solitaire provides enhanced functionality for Android based payment
            terminals when used in a standalone environment or as a backup for a
            POS connectivity failure.
          </p>
          <p>
            In a restaurant there is an option to split a check into multiple
            payments.
          </p>
          <p>
            Once the amount appears on the terminal screen, the terminal is
            passed to the customer.
          </p>
          <p>The customer will:</p>
          <ul>
            <li>1 - confirm the amount</li>
            <li>2 - add a tip</li>
            <li>3 - add a donation</li>
          </ul>
          <p>
            And pass the terminal back to the server to process the payment.
          </p>
          <p>
            Multiple payments can be made against each check including
            debit/credit, gift card, prepaid gift card, or cash.
          </p>
          <p>
            Physical gift cards can be sold or funded by scanning the bar code
            or QR code with the camera on the terminal.
          </p>
        </section>
      </main>
    </div>
  );
};

export default StandAlone;
